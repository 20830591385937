@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "DIN Alternate Bold";
  src: local("DIN Alternate Bold"), url("../public/fonts/DINAlternate-Bold.woff") format("woff");
}

@font-face {
  font-family: "D-DIN-Condensed-Bold";
  src: url("../public/fonts/D-DIN-Condensed-Bold.woff") format("woff");
}

@font-face {
  font-family: "D-DIN-Condensed-normal";
  src: url("../public/fonts/DIN-Condensed_Normal.otf") format("otf");
}

.font-din {
  font-family: "DIN Alternate Bold", sans-serif;
}

.font-ibm {
  font-family: "IBM Plex Sans", sans-serif;
}

.font-d-din-normal {
  font-family: "D-DIN-Condensed-normal", sans-serif;
}

.header-shadow {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}

body {
  @apply bg-[#F1F1F1] scroll-smooth scroll-mt-[155px];
}

/* Dropdown Button */
.dropdown-btn:focus + .dropdown-content,
.dropdown-btn:hover + .dropdown-content,
.dropdown-content:hover {
    display: block;
}

/* Dropdown Menu */
.dropdown-content {
    display: none;
    min-width: 160px;
    z-index: 1;
}

/* Optional: Styles for dropdown links on hover */
.dropdown-content a:hover {
    background-color: #f1f1f1;
}

html {
    scroll-behavior: smooth;
}